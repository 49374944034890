<template>
  <div class="footer">
    <div class="footer-div1 shadow rounded mx-auto text-center">
      <p class="mt-5 font-bold text-white font-30">Let's move you</p>
      <span class="mb-2 text-light">Contact us and book your next movement.</span>
      <p class="mb-5 text-light">For long distance moves contact directly</p>
      <b-row class="mx-auto my-10 d-flex flex-column flex-sm-row">
        <b-col cols="12" md="3" class="order-1 order-sm-1" />
        <b-col cols="12" md="2" class="order-3 order-sm-2">
          <v-btn fab color="white" @click="callPhone">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
          <p class="mt-5 text-light">747-253-6334</p>
        </b-col>
        <b-col cols="12" md="4" class="order-2 order-sm-3">
          <v-btn fab color="white" @click="sendEmail">
            <v-icon>mdi-email</v-icon>
          </v-btn>
          <p class="mt-5 text-light">keanu@movxmovingcompany.org</p>
        </b-col>
        <b-col cols="12" md="3" class="order-4 order-sm-4" />
      </b-row>
      <v-btn class="mx-auto p-2 text-capitalize rounded" style="width: 250px; background-color: black;" @click="doBecomeAgent" outlined>
        <span class="m-5 text-light">Become a MovX Agent</span>
        <img :src="assets.arrowRight" :width="15" />
      </v-btn>
    </div>
    <div class="footer-div2">
      <b-row class="m-5">
        <b-col cols="12" md="7">
          <span class="text-light mt-5">&copy; Copyright {{ new Date().getFullYear() }},&nbsp;All Rights Reserved</span>
        </b-col>
        <b-col cols="12" md="1">
          <v-btn class="text-capitalize" text @click="$router.push('/#contact_us').catch(()=>{})">
            <span class="text-light">Support</span>
          </v-btn>
        </b-col>
        <b-col cols="12" md="2">
          <v-btn class="text-capitalize" text @click="$router.push('/terms').catch(()=>{})">
            <span class="text-light">Terms &copy; Conditions</span>
          </v-btn>
        </b-col>
        <b-col cols="12" md="2">
          <v-btn class="text-capitalize" text @click="$router.push('/#policy').catch(()=>{})">
            <span class="text-light">Privacy &copy; Policy</span>
          </v-btn>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/sass/layout/_footer.scss";
</style>

<script>
import logo from '@/assets/image/logo.png';
import arrowRight from '@/assets/image/arrow_right.png';

export default {
  name: "KTFooter",
  data() {
    return {
      assets: {
        logo,
        arrowRight
      }
    }
  },
  methods: {
    callPhone() {
      window.location.href = 'tel:747-253-6334';
    },
    sendEmail() {
      window.location.href = 'mailto:keanu@movxmovingcompany.org';
    },
    doBecomeAgent() {
      this.$toast.info('Become A MovX Agent');
    }
  }
};
</script>
